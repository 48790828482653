<template>
    <div class="view_container">
        
        <div id="content_main">
            
        </div>

        <div id="content_footer">
        
        </div>

    </div>

</template>

<script>

export default {
    data: () => ({

    }),

    created() {

    },

    mounted() {

    },

    unmounted() {

    }
}

</script>

<style scoped>

</style>